import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { CPSConfigurator, CPSConfigEvent, CPSData, CPSConfigEventCommands, CPSConfigFunctions, isVisible, ImageTypes, ImageCharateristicValueTypes, ImageRotation } from '@danfoss/cpsconfigurator'
//import '@danfoss/cpsconfigurator/dist/index.css';
import '@danfoss/mosaic/css/mosaic.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import settings from './config/settings';
import queryString from 'query-string';
import ConfigResult from './Components/configResult';
import { CPSCharacteristic } from '@danfoss/cpsconfigurator/dist/components/CPSConfig/types';
import AuthenticationProvider from './Components/Provider/AuthenticationProvider';
import ContentWrapper from './Components/contentWrapper';

const productKeySettings = [
    {
        kmatKey: "HA_CM",
        initialCharacteristics: [{ id: 'IPC_REF', value: 'X' }],
        resultCharacteristics: [{ id: 'HA_CM_FPARTNO1' }, { id: 'HA_CM_FPARTNO2' }, { id: 'HA_CM_HPARTNO' }, { id: 'HA_CM_CSHOSELEN' }, { id: 'HA_CM_HOCULEI' }, { id: 'HA_CM_HALENUOM' }, { id: 'STAT_GROUP' }],
        saveCharacteristics: [{ id: 'HA_CM_FPARTNO1' }, { id: 'HA_CM_FPARTNO2' }, { id: 'HA_CM_HPARTNO' }, { id: 'HA_CM_CSHOSELEN' }, { id: 'HA_CM_HOCULEI' }, { id: 'HA_CM_HALENUOM' }, { id: 'STAT_GROUP' }],
        disableShowMatchingResults: false,
        disableDecodeModelCode: false,
        decodeParams: {
            disableDecode: false,
            decodeSettings: {
                enableWhen: {
                    id: 'CF_DECODE',
                    value: 'Y'
                },
                setInitialCharacteristics: [
                    {id: 'CF_REGION'}
                ]
            }
        },
        designParams: {
            resultButtonText: 'Show result',
            reviewButtonText: 'View assembly BOM',
            noPriceText: 'No price, contact support',
            hideProgressBar: false
        },
        includeValueInDescription: true,
        setLongDescriptionForValues: true,
        useTabs: true,
        onlyShowRequired: true,
        language: 'en',
        imageBlock: {
            assetApiSettings: { imageType: ImageTypes.Drawings, publicationChannelDefinitionIds: ['1110'] },
            imageCharacteristicValueType: ImageCharateristicValueTypes.CodeNumbers,
            images: [{ characteristic: 'CF_HA_CM_FPARTNO1' }, { characteristic: 'CF_HA_CM_HPARTNO' }, { characteristic: 'CF_HA_CM_FPARTNO2', rotation: ImageRotation.MirrorHorizontal }],
            footerTextCharacteristics: ['HA_CM_CSHOSELEN', 'HA_CM_HALENUOM'],
            defaultImages: [{ characteristic: 'CF_HA_CM_FPARTNO1', imageURL: `${settings.ha_cm.HA_CM_Fitting_Image}` }, { characteristic: 'CF_HA_CM_HPARTNO', imageURL: `${settings.ha_cm.HA_CM_Hose_Image}` }, { characteristic: 'CF_HA_CM_FPARTNO2', imageURL: `${settings.ha_cm.HA_CM_Fitting_Image}`, rotation: ImageRotation.MirrorHorizontal }]
        },
        showSave: true,
        showPrice: true,
        saveReturnFullConfigAsXML: true,
    },
    {
        kmatKey: "100_SER",
        initialCharacteristics: [{ id: 'IPC_REF', value: 'X' }],
        resultCharacteristics: [],
        saveCharacteristics: [],
        disableShowMatchingResults: false,
        disableDecodeModelCode: false,
        showSave: true,
        showPrice: true,
        decodeParams: {
            disableDecode: false
        },
    },
    {
        kmatKey: "300_SER",
        initialCharacteristics: [{ id: 'IPC_REF', value: 'X' }, { id: "FC_VLT_SERIES_300", value: "301" }],
        resultCharacteristics: [],
        saveCharacteristics: [],
        disableShowMatchingResults: false,
        disableDecodeModelCode: false,
        language: 'en',
        showSave: true,
        showPrice: true
    },
    {
        kmatKey: "CYLF_CM",
        disableShowMatchingResults: false,
        disableDecodeModelCode: false,
        saveCharacteristics: [],
        language: 'en',
        showSave: true,
        showPrice: true,
        saveReturnFullConfigAsXML: true
    }
]

const generalResultCStics = [{ id: 'AMC_PRDHA' }];

interface CustomEventMap {
    'CPS_CONFIG_RESULT': CustomEvent<CPSConfigEvent>
}

declare global {
    interface Window { //adds definition to Document, but you can do the same with HTMLElement
        addEventListener<K extends keyof CustomEventMap>(type: K,
            listener: (this: Document, ev: CustomEventMap[K]) => void): void;
        removeEventListener<K extends keyof CustomEventMap>(type: K,
            listener: (this: Document, ev: CustomEventMap[K]) => void, options?: boolean): void;
    }
}

const root = createRoot(document.querySelector('.cps-container') as HTMLElement);

const parseInitialCharacteristics = (initialCharacteristics: string) => {
    if (!initialCharacteristics) return undefined;
    
    const initialCharacteristicsArray = initialCharacteristics.split(';');
    
    return initialCharacteristicsArray.map(ic => {
        const [id, value] = ic.split(',');
        return { id, value };
    });
}

const Main: React.FC = () => {
    const [productKey, setProductKey] = useState('');
    const [qslanguage, setQslanguage] = useState('');
    const [qsincludeValueInDescription, setQsincludeValueInDescription] = useState(false);
    const [qsSetLongDescriptionForValues, setQsSetLongDescriptionForValues] = useState(false);
    const [qsSetPrice, setQsSetPrice] = useState(false);
    const [qsHideProgressBar, setQsHideProgressBar] = useState(false);
    const [qsScrollToNextCstic, setQsScrollToNextCstic] = useState(false);
    const [qsSetSalesOrg, setQsSetSalesOrg] = useState('');
    const [qsSetCustomerNo, setQsSetCustomerNo] = useState('');
    const [qsSetInitialCharacteristics, setQsSetInitialCharacteristics] = useState([]);
    const [qsModelCode, setQSModelCode] = useState(undefined);
    
    const [configResult, setConfigResult] = useState({} as CPSConfigEvent);
    const [configFunction, setConfigFunction] = useState(CPSConfigFunctions.configure);
    const [configData, setConfigData] = useState({} as CPSData);
    const [showResult, setShowResult] = useState(false);
    const [saveResult, setSaveResult] = useState(null);
    const [confAccepted, setConfAccepted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contentWrapperLoading, setContentWrapperLoading] = useState(true);
    const [isCPSConfigVisible, setIsCPSConfigVisible] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useEffect(() => {

        //Get querystring parameters
        setLoading(true);
        const parsedQueryString = queryString.parse(window.location.search);
        const { product_key, language, includevalueindescription, setlongdescriptionforvalues, showprice, hideprogressbar, salesorg, customerno, initialcharacteristics, scrolltonextcstic, modelcode} = parsedQueryString;
        if (product_key) {
            setProductKey(product_key as string);
        }
        else {
            setProductKey('HA_CM');
        }

        setQslanguage(language as string);
        setQsincludeValueInDescription(includevalueindescription === 'true' ? true : false);
        setQsSetLongDescriptionForValues(setlongdescriptionforvalues === 'true' ? true : false);
        setQsSetPrice(showprice === 'true' ? true : false);
        setQsHideProgressBar(hideprogressbar === 'true' ? true : false);
        setQsSetCustomerNo(customerno || settings.pricingData.customerno);
        setQsSetSalesOrg(salesorg || settings.pricingData.salesorg);
        setQsScrollToNextCstic(scrolltonextcstic ? scrolltonextcstic === 'true' : productKeySettings.find(pks => pks.kmatKey === (product_key || 'HA_CM'))?.designParams?.scrollToNextCstic);
        setQSModelCode(modelcode || undefined);
        
        setQsSetInitialCharacteristics(parseInitialCharacteristics(initialcharacteristics as string) || productKeySettings.find(pks => pks.kmatKey === (product_key || 'HA_CM'))?.initialCharacteristics || []);

        // When running in production and development with micro-site package installed from aritfactory we must import index.min.css
        // When running in development with link to local micro-site package inlne styles are used so no index.min.css is available
        try {
            require('@danfoss/cpsconfigurator/dist/index.min.css');
        } catch (e) {
        }
        // }
    }, []);

    useEffect(() => {
        const showConfigResultListener = (event: CustomEvent<CPSConfigEvent>) => {
            setConfigResult(event.detail);
            setConfigData({configId: event.detail.result?.configId || ''});

            if (event.detail.command === CPSConfigEventCommands.show) {
                setShowResult(true);

                console.log('Show result:', event.detail.result);

                setConfigFunction(CPSConfigFunctions.display);
            } else if (event.detail.command === CPSConfigEventCommands.save) {

                console.log('Save result:', event.detail.result);

                window.localStorage.setItem('ConfToLoad', JSON.stringify(event.detail.result));
            } else
                setShowResult(false);
        }



        window.addEventListener('CPS_CONFIG_RESULT', showConfigResultListener);

        return () => {
            window.removeEventListener('CPS_CONFIG_RESULT', showConfigResultListener, false);
        }


    }, []);

    useEffect(() => {
        const checkVisibility = async () => {
            setLoading(true);
            const visible = await (isVisible({ cfhId: '', segment: 'DPS', kmatKey: productKey, configParams: { apiBaseUrl: settings.cpsConfiguratorApi.endpoint, apiHashKey: settings.cpsConfiguratorApi.hashkey } }));
            setIsCPSConfigVisible(visible.isVisible);
            setLoading(false);
        }
        if (productKey) {
            checkVisibility();
        }
    }, [productKey]);

    const onDisplay = () => {
        setConfigFunction(CPSConfigFunctions.display);
    }

    const onReconfigure = () => {
        setConfigFunction(CPSConfigFunctions.configure);
        setShowResult(false);
    }

    const loadConf = () => {
        setConfigFunction(CPSConfigFunctions.load);
        setConfigData(JSON.parse(window.localStorage.getItem('ConfToLoad') || '{}') as CPSData);
        setProductKey((JSON.parse(window.localStorage.getItem('ConfToLoad') || '{}') as CPSData).kmat);

        //window.localStorage.removeItem('ConfToLoad');
        setShowResult(false);
        setSaveResult(null);
    }

    if (!productKey)
        return null;

    return (
        <div className='df-mosaic'>
            <AuthenticationProvider
                domain={settings.authProvider.domain}
                clientId={settings.authProvider.clientId}
            >
                <ContentWrapper setIsUserAuthenticated={setIsUserAuthenticated} setLoading={setContentWrapperLoading}>
                    <Header />

                    <div className="df-main-content pt-5 pb-5" tabIndex={-1} role="main">
                        <div className='df-container'>
                            <nav aria-label="Breadcrumbs navigation" className='mt-3'>
                                <ul className="df-breadcrumbs">
                                    <li className="df-breadcrumb df-hidden-sm"><a href="#">Home</a></li>
                                    <li className="df-breadcrumb"><a href="#" aria-current="page">Configure product</a></li>
                                </ul>
                            </nav>
                        </div>
                        {!saveResult && configFunction === CPSConfigFunctions.display &&
                            <ConfigResult showConfigEvent={configResult} onDisplay={onDisplay} onReconfigure={onReconfigure} />
                        }

                        {!contentWrapperLoading && !loading && !isCPSConfigVisible && productKey &&
                            <div className='df-container'>
                                <div className="df-col-6">
                                    <div id="alert" className='df-alert alert-error' role="alert">
                                        <div className="df-alert-message">
                                            <p className="alert-heading">{`THe CPS configurator reponded with not visible which means that configurator with product key ${productKey} does not exist`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {localStorage.getItem('ConfToLoad') &&
                            <div className='df-container'>
                                <a href="#" onClick={() => loadConf()}>Load configuration</a>
                                <p>CONF ID: {JSON.parse(localStorage.getItem('ConfToLoad') || '').configId}</p>
                                <p>Kmat: {JSON.parse(localStorage.getItem('ConfToLoad') || '').kmat}</p>
                                <p>Data: {JSON.stringify(JSON.parse(localStorage.getItem('ConfToLoad') || '').characteristics || (JSON.parse(localStorage.getItem('ConfToLoad') || '').xml as string)).substring(0,50)}</p>
                            </div>
                        }


                        {!contentWrapperLoading && !loading && isCPSConfigVisible && !showResult && !confAccepted &&
                            <CPSConfigurator
                                kmatKey={productKey}
                                initialCharacteristics={qsSetInitialCharacteristics}
                                resultCharacteristics={[...productKeySettings.find(pks => pks.kmatKey === productKey)?.resultCharacteristics || []] as CPSCharacteristic[]}
                                saveCharacteristics={[...productKeySettings.find(pks => pks.kmatKey === productKey)?.saveCharacteristics || []] as CPSCharacteristic[]}
                                language={qslanguage || productKeySettings.find(pks => pks.kmatKey === productKey)?.language || 'en'}
                                configParams={{ apiBaseUrl: settings.cpsConfiguratorApi.endpoint, apiHashKey: settings.cpsConfiguratorApi.hashkey }}
                                configFunction={configFunction}
                                configData={configData}
                                useTabs={productKeySettings.find(pks => pks.kmatKey === productKey)?.useTabs || false}
                                onlyShowRequired={productKeySettings.find(pks => pks.kmatKey === productKey)?.onlyShowRequired || false}
                                disableShowMatchingResults={productKeySettings.find(pks => pks.kmatKey === productKey)?.disableShowMatchingResults || false}
                                disableDecodeModelCode={productKeySettings.find(pks => pks.kmatKey === productKey)?.disableDecodeModelCode || false}
                                includeValueInDescription={qsincludeValueInDescription || productKeySettings.find(pks => pks.kmatKey === productKey)?.includeValueInDescription}
                                setLongDescriptionForValues={qsSetLongDescriptionForValues || productKeySettings.find(pks => pks.kmatKey === productKey)?.setLongDescriptionForValues}
                                imageBlock={productKeySettings.find(pks => pks.kmatKey === productKey)?.imageBlock}
                                decodeParams={{...productKeySettings.find(pks => pks.kmatKey === productKey)?.decodeParams, modelCode: qsModelCode}}
                                designParams={{
                                  hideProgressBar: (qsHideProgressBar || (productKeySettings.find(pks => pks.kmatKey === productKey)?.designParams?.hideProgressBar || false)),
                                  resultButtonText: (productKeySettings.find(pks => pks.kmatKey === productKey)?.designParams?.resultButtonText || 'Show result'),
                                  reviewButtonText: (productKeySettings.find(pks => pks.kmatKey === productKey)?.designParams?.reviewButtonText || 'Review result'),
                                  scrollToNextCstic: (qsScrollToNextCstic),
                                }}
                                userParams={{
                                    isLoggedIn: isUserAuthenticated, 
                                    customerNo: qsSetCustomerNo, 
                                    salesOrg: qsSetSalesOrg, 
                                    showSave: productKeySettings.find(pks => pks.kmatKey === productKey)?.showSave || true, 
                                    saveReturnFullConfigAsXML: productKeySettings.find(pks => pks.kmatKey === productKey)?.saveReturnFullConfigAsXML || false, 
                                    showPrice: (qsSetPrice || productKeySettings.find(pks => pks.kmatKey === productKey)?.showSave || true)
                                }}
                                pdfExportParams={{
                                    exportLinkName: 'Print',
                                    appName: 'CPS test app',
                                    disclaimerText: 'CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text, CPS test page disclaimer text',
                                    returnToLink: productKey !== 'HA_CM' && `${window.location.origin}?product_key={productkey}&modelcode={modelcode}`,
                                }}
                                customConflictParams={
                                [
                                    {
                                        cSticId: 'HA_CM_HPARTNO',
                                        cSticValue: "B-4",
                                        conflictText: "TESTING CUSTOM CONFLICT MESSAGE PART NO"
                                    },
                                    {
                                        cSticId: 'HA_CM_FPARTNO1',
                                        cSticValue: "B-1104-1",
                                        conflictText: "TESTING CUSTOM CONFLICT MESSAGE SERIES"
                                    }
                                ]}
                                authParams={{ "domain": settings.authProvider.domain, "clientId": settings.authProvider.clientId }}
                                showErrorPopup={false}
                            />
                        }
                    </div>
                    <Footer />
                </ContentWrapper>
            </AuthenticationProvider>
        </div>
    );
}
root.render(<Main />);